import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router,ActivatedRoute ,NavigationExtras }              from '@angular/router'; 
import { CreateTargetComponent }  from '../create-target/create-target.component';
import { AdminComponent } from '../admin/admin.component';
import { ApiService } from '../api.service';
import { FormGroup , FormControl ,Validators, NgForm} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit,OnDestroy {
data:any={};
response:any;
email_check:boolean=false;
data_store:any={};
  constructor(private router: Router,public apiService:ApiService) { }

 form = new FormGroup({
         username: new FormControl('', Validators.required),
         password: new FormControl('', Validators.required)
  });

  ngOnInit() {
  }

  ngOnDestroy(){
    console.log("login OnDestroy called")
  }

	login(){		
		console.log("data",this.data)
		this.apiService.register("https://dev.krowdbox.com/api/login",this.data).subscribe((res)=>{
              // console.log("res",res) 
              this.response=res;
                if(this.response=="Invalid email or password"){
                    this.email_check=true;
                }
                else {  
                	 	this.data_store = this.response;
                    console.log("res",this.response)
                    this.response=res['0'];
                    localStorage.setItem("user_data",JSON.stringify(this.data_store));
                    sessionStorage.setItem("user_logged","true");  
                           this.router.navigate(['/maindashboard']); 

                 }
              });
		
	}

  person(){
    this.router.navigate(['/personal']); 
  }


  register(){
	
		this.router.navigate(['/personal']);
	}

	onKeyPress(){
	  console.log("onKeyPress")
	  this.email_check=false;
  }
  forget(){
    this.router.navigate(['/forget']);
  }
}
