import { Component, OnInit }   from '@angular/core';
import { Router }              from '@angular/router';

import { Address }             from '../data/formData.model';
import { FormDataService }     from '../data/formData.service';
import { ApiService } from '../api.service';
declare var jquery:any;
declare var $ :any;

@Component ({
    selector:     'mt-wizard-address'
    ,templateUrl: './address.component.html'
})

export class AddressComponent implements OnInit {
    address: Address;
    form: any;
    data:any={};
    check_id_number:any;
    response:any;
    data_:any;
    ckeck_number:boolean=false;
    checking_id:boolean=false;
    ckeck_number_error:boolean=false;
    disabled:false;
     isDisabled :boolean = false;
     id_already:boolean=false;
     Record_error:boolean=false;
     valid:boolean=false;
     age:boolean=false;
     alive:boolean=false;
     pending:boolean=false;
  manual: boolean;
  manualverification: boolean;
  data1: any;
    constructor(public apiService:ApiService,private router: Router, private formDataService: FormDataService) {
    }

    ngOnInit() {

     
        this.address = this.formDataService.getAddress();
        console.log('Address feature loaded!');
    }

    save(form: any): boolean {
        if (!form.valid) {
            return false;
        }
            
        this.formDataService.setAddress(this.address);
        return true;
    }

   goToNext(form: any) {
    // console.log(this.router.navigate(['/result']));

        if (this.save(form)) {
          console.log("tayab checking data", form.value.id_number);
          this.ckeck_number_error=false;
          this.data1 = form.value.id_number;
            // Navigate to the result page
            this.data_ =JSON.parse(localStorage.getItem("data"));
            let first=this.data_['0'];
            // console.log("first",first.firstname)
            console.log("this data1",this.data1)
            console.log("african id to check if present in db",this.data.id_number);
//*********************uncommented by tayyab**************************************8888 */
           this.apiService.post_data1("https://dev.krowdbox.com/api/checkAfricanID",this.data1).subscribe((res)=>{
              console.log("data_exist or not" ,res)
                if(res=='Yes'){
                  console.log("yes")
                  this.id_already=true;
                }
                else{
                      this.checking_id=true;
                        console.log("no")
                    this.apiService.getData1("https://dev.krowdbox.com/test.php?action=get_request_id&id="+this.data1).subscribe((res)=>{
                    console.log("res",res)
                    this.response=res['id']
                    console.log("get_request_id",this.response)


                    this.isDisabled=true;
                    
                  setTimeout(() => {
                            this.apiService.getData("https://dev.krowdbox.com/test1.php?action=get_response&request_id="+this.response).subscribe((res)=>{
                          console.log("respone_check",res)
                            let some_data=res['response'];
                            
                            let some_data_other=some_data['body'];
                            let some_data_other1=some_data_other['data'];
                               
                            let some_data_other2=some_data_other1['IDCheck'];
                            
                            // let some_data_other3=some_data_other2['first_names'];
                            
                            //code from tayyab for manual verification

                            if(some_data['result']== "ERROR"){
                              let some_data_other=some_data['body'];
                              let some_data_other1=some_data_other['data'];
                              
                              let some_data_other2=some_data_other1['IDCheck'];
                                if(some_data_other2 == undefined || some_data_other2 == '' || some_data_other2 == null){
                                  this.checking_id=false;
                                  this.manualverification = true;
                                  this.manual = true;

                                  localStorage.setItem("data_african_id",this.data.id_number);
                                  localStorage.setItem("verification","Manual");
                                  this.router.navigate(['/result']);
                                        //mereko manual karneka hai
                                }
                              else {
                                    if(some_data_other2['id_status'] == "VALID"){
                                      this.checking_id=false;
                                      this.manualverification = true;
                                      this.manual = true;
                                      localStorage.setItem("data_african_id",this.data.id_number);
                                      localStorage.setItem("verification","Manual");
                                      this.router.navigate(['/result']);
                                        //mereko manual karneka hai

                                    }
                                    else{
                                      this.checking_id=false;
                                      this.Record_error = true;

                                    }

                              }



                            }

                           else if(some_data['result']== "OK"){
                              let some_data_other=some_data['body'];
                              let some_data_other1=some_data_other['data'];
                              
                              let some_data_other2=some_data_other1['IDCheck'];
                              
                             // let some_data_other3=some_data_other2['first_names'];
                           
                            

                                  if(some_data_other2['first_names']=="No Record Found" && some_data_other2['last_name'] == "No Record Found")                      
                                  {
                                    console.log("please check your id no 1")
                                    this.ckeck_number=true;
                                    this.checking_id=false;
                                    this.isDisabled=true;
                                   
                                    }
                                    else 
                                {
                                      console.log("name:",some_data_other2['first_names'] )
                                      let some_data_other3 = some_data_other2['first_names'];
                                      some_data_other3 = some_data_other3.split(" ");

                                      let fname = first.firstname.toUpperCase().trim();
                                      console.log("fname",fname)
                                      let fname_split = fname.split(" ");
                                      console.log("1",fname_split[0]);
                                      console.log("2",fname_split[1]);
                                      console.log("3",fname_split[2]);

                                  if(some_data_other2['age'] >=18 && (some_data_other2['vital_status'] == "Alive" || some_data_other2['vital_status'] == "ALIVE")  && some_data_other2['id_status'] == "VALID" )
                                  {
                                    console.log("condition satisfied");
                                        if((fname_split[0]==(some_data_other3[0] || some_data_other3[1] || some_data_other3[2]))&& first.surname.toUpperCase().trim()==some_data_other2['last_name'].toUpperCase().trim()){
                                          localStorage.setItem("data_african_id",this.data.id_number)
                                          localStorage.setItem("verification","Active");
                                          console.log("continue");
                                          this.router.navigate(['/result']);
                                      }

                                      if(((fname_split[0]==some_data_other3[0]) || (fname_split[0]==some_data_other3[1]) || (fname_split[0]==some_data_other3[2]))&& first.surname.toUpperCase().trim()==some_data_other2['last_name'].toUpperCase().trim()){
                                        localStorage.setItem("data_african_id",this.data.id_number)
                                        localStorage.setItem("verification","Active");
                                        console.log("continue");
                                        this.router.navigate(['/result']);
                                    }

                                      if((fname_split[1]==(some_data_other3[0] || some_data_other3[1] ||some_data_other3[2]))&& first.surname.toUpperCase().trim()==some_data_other2['last_name'].toUpperCase().trim()){
                                        localStorage.setItem("data_african_id",this.data.id_number)
                                        localStorage.setItem("verification","Active");
                                        console.log("continue");
                                        this.router.navigate(['/result']);
                                    }

                                    
                                    console.log("fname_split[0]",fname_split[0])
                                    console.log("fname_split[0] condition",(fname_split[0]==(some_data_other3[0] || some_data_other3[1] || some_data_other3[2])));
                                    console.log("some_data_other3[0]",some_data_other3[0])
                                    console.log("some_data_other3[1]",some_data_other3[1])
                                    console.log("some_data_other3[2]",some_data_other3[2])



                                    console.log("some_data_other3[0]",some_data_other3[0])
                                    console.log("fname_split[1] condition",fname_split[0])
                                    console.log("fname_split[1]",fname_split[1])
                                    console.log("fname_split[0]",(fname_split[1]==(some_data_other3[0] || some_data_other3[1] || some_data_other3[2])))



                                      if( ((fname_split[0]==(some_data_other3[0] || some_data_other3[1] || some_data_other3[2])) && (fname_split[1]==(some_data_other3[0] || some_data_other3[1] || some_data_other3[2]))) && first.surname.toUpperCase().trim()==some_data_other2['last_name'].toUpperCase().trim()){
                                        console.log("rinki")
                                        localStorage.setItem("data_african_id",this.data.id_number)
                                        localStorage.setItem("verification","Active");
                                        console.log("continue");
                                        this.router.navigate(['/result']);
                                      }

                                      if( (((fname_split[0] == some_data_other3[0]) || (fname_split[0]==some_data_other3[1]) || (fname_split[0]== some_data_other3[2])) && ((fname_split[1]==some_data_other3[0]) || (fname_split[1]==some_data_other3[1]) || (fname_split[1]==some_data_other3[2]))) && first.surname.toUpperCase().trim()==some_data_other2['last_name'].toUpperCase().trim()){
                                        console.log("rathore")
                                        localStorage.setItem("data_african_id",this.data.id_number)
                                        localStorage.setItem("verification","Active");
                                        console.log("continue");
                                        this.router.navigate(['/result']);
                                      }

                                   
                                      if(first.firstname.toUpperCase().trim()==some_data_other2['first_names'].toUpperCase().trim() && first.surname.toUpperCase().trim()==some_data_other2['last_name'].toUpperCase().trim())
                                      {
                                          localStorage.setItem("data_african_id",this.data.id_number)
                                          localStorage.setItem("verification","Active");
                                           console.log("continue");
                                           this.router.navigate(['/result']);
                                      }
                                      else{
                                           console.log("please check your id no 2")
                                        
                                           this.checking_id=false;
                                           this.isDisabled=true;
                                           this.Record_error=true;
                                      }
                                  }
                                  else{
                                    // alert("failed");
                                    if(some_data_other2['age'] < 18){
                                      this.age=true;
                                      console.log("age");
                                      this.checking_id=false;
                                    }
                                    if(some_data_other2['vital_status'] != "ALIVE" || some_data_other2['vital_status'] != "Alive"){
                                      this.alive=true;
                                      console.log(some_data_other2['vital_status']);
                                      this.checking_id=false;
                                    }
                                    if(some_data_other2['id_status'] != "VALID"){
                                      console.log("VALID");
                                      this.checking_id=false;
                                    }

                                  }
                                  
                                }
                              }
                              else if(some_data['result']== "PENDING"){
                                  console.log("else if")
                                  this.ckeck_number_error=true;
                                  this.checking_id=false;
                                  this.isDisabled=true;
                              }                             
                              else if(some_data_other2['id_status']== "INVALID" ){
                                console.log("please check your id no 3")
                                this.ckeck_number=true;
                                this.checking_id=false;
                                this.isDisabled=false;
                              }
                              else{
                                this.pending=true;
                           }

                            });
                        },10000);
                    });
                  }            

            }) 
// **********************************8uncommented by tayyab**************************************88
    
   
     // this.router.navigate(['/result']);


       
            
        }
    }

    function1(){
      this.check_id_number=false 
      this.ckeck_number=false
      this.id_already=false;
      this.valid=false;
      this.age=false;
      this.alive=false;
      this.pending=false;
      this.Record_error=false;
    }
}