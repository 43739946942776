import { NgModule, } from '@angular/core';
import { CommonModule ,DatePipe} from '@angular/common';

import { CreateTargetRoutingModule } from './create-target-routing.module';
import { CreateGoalComponent } from '../create-goal/create-goal.component';


import {FormsModule,ReactiveFormsModule,} from '@angular/forms';

import {MatTabsModule,MatProgressBarModule,MatExpansionModule,MatDatepickerModule,MatTableModule,
  MatNativeDateModule ,MatFormFieldModule,MatInputModule,MatCardModule,MatDialogModule,MatSelectModule,MatMenuModule,MatIconModule} from '@angular/material';
import { ReviewGoalComponent } from '../review-goal/review-goal.component';
import { ReviewDialogComponent } from '../review-dialog/review-dialog.component';
import { BankComponent } from '../bank/bank.component';
import { VerifiedBankComponent } from '../verified-bank/verified-bank.component';
// import { CreateTargetComponent }  from '../create-target/create-target.component';
import { ShareGoalComponent } from '../share-goal/share-goal.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SettingsComponent } from '../settings/settings.component';
import { Dashboard1Component } from '../dashboard1/dashboard1.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {NgbModule,NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {TabModule} from 'angular-tabs-component';
import { MsgComponent } from '../msg/msg.component';
import { SendgiftComponent } from '../sendgift/sendgift.component';

import { DateParserFormatter } from '../date-parser-formater';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReceivePaymentComponent } from '../receive-payment/receive-payment.component';
@NgModule({
  imports: [
    CommonModule,
    CreateTargetRoutingModule,
     MatExpansionModule,
                     MatFormFieldModule,
                     MatCardModule,
                     MatInputModule,
                     MatDialogModule,
                     MatSelectModule,
                     FormsModule,
                     MatMenuModule,
                     MatTableModule,
                     ReactiveFormsModule,
                     MatDatepickerModule,
                     MatNativeDateModule,MatProgressBarModule,
                     NgCircleProgressModule.forRoot({
                                  radius: 100,
                                  outerStrokeWidth: 16,
                                  innerStrokeWidth: 8,
                                  outerStrokeColor: "#78C000",
                                  innerStrokeColor: "#C7E596",
                                  animationDuration: 300,
                                  
                     }),
                     MatTabsModule,
                     NgbModule,
                     TabModule,
                     MatIconModule,NgxPaginationModule
  ],
  declarations: [MsgComponent,Dashboard1Component,SettingsComponent,DashboardComponent,CreateGoalComponent,ReviewGoalComponent,BankComponent,VerifiedBankComponent,ReviewDialogComponent, ShareGoalComponent,SendgiftComponent,ReceivePaymentComponent],
   exports: [
              MatExpansionModule,
              MatFormFieldModule,
              MatInputModule,
              MatCardModule,
              MatMenuModule,
              MatDatepickerModule,
              MatNativeDateModule ,
             MatSelectModule,
            CreateGoalComponent,
            FormsModule,
            ReactiveFormsModule,
            BankComponent,
            VerifiedBankComponent,
            MatTableModule,
            NgCircleProgressModule,
            MatTabsModule,
            NgbModule,
            TabModule,MatIconModule,
            
            ],
   entryComponents: [MsgComponent,ReviewGoalComponent,ReviewDialogComponent],
   providers: [DatePipe,MatDatepickerModule,NgCircleProgressModule,{ provide: NgbDateParserFormatter, useClass: DateParserFormatter }]
})
export class CreateTargetModule { }

