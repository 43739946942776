import { Component, OnInit, ViewChild,OnDestroy ,Pipe, PipeTransform, ViewEncapsulation} from '@angular/core';
import { MatTableDataSource, MatSort,MatDialog,MatDialogRef } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
// import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from '../api.service';
import { Router,ActivatedRoute ,NavigationExtras }from '@angular/router'; 
import * as moment from 'moment';
import { MsgComponent } from '../msg/msg.component';

import { DatePipe } from '@angular/common';
import { AbsoluteSourceSpan } from '@angular/compiler';
declare var jquery:any;
declare var $ :any;


export interface Element {
  Name: string,
  Message: string,
  Contribution: string,
  Date: string,
  


}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  // encapsulation: ViewEncapsulation.None
})

export class DashboardComponent implements OnInit,OnDestroy{
  fee:boolean=false;
  terminate:boolean=false;
  msg:boolean=true;
  user:any={};
  receiveUser:any={};
  dataSource;
  edit:boolean=false;
  goal_id:any;
  displayedColumns = [];
  amount_raised:any;
  target_amount:any;
  target_date:any;
  fee_amount_raised:any;
  fee_balance_to_you:any;
  contributors:any;
  goal_name:any;
fee_payment_gateway:any;
fee_platform_fee:any;
tableArr:any;
status:any;
date:boolean=true;
dateReceive:boolean=true;
  msg_row: boolean;
  msg_con: any;
  showDialog: boolean;
  empty: boolean;
  successMessage: string;
  display_msg: any;
  variable: Object;
  _date: any;
  _dateReceive: any;
  share: boolean;
  encID: string;
  gift_id: any;
  payment_id:any;
  giftDiv: boolean;
  goalDiv: boolean;
  paymentDiv: boolean;
  giftName: Object;
  giftNumber: any;
  giftAmount: any;
  giftCreate_at: any;
  giftEmpty: boolean;
  paymentEmpty: boolean;
  giftMsg: any;
  giftStatus: any;
  cancelButton: boolean;
  cashGiftUrl: boolean;
  cashgiftMsg: boolean;
  cashUrlDiv: boolean;
  terminated_balance: number;
  goalData: any={};
  receiveData: any={};
  minDate: string;
  terminated_balance1: string;
  activeGoal: boolean;
  config: any;
  payment_amount: any;
  receiveStatus: any;
  activeReceive: boolean;
  payment_reference: any;
  payment_date: any;
  receiveAmount_raised: number;
  platform_fee: number;
  editReceive: any;
  receiveTerminate: boolean;
  receiveMsg: boolean=true;
  receiveFee: boolean;
  receiveShare: boolean;
  customers: any;
  balance_to_pay: any;
  
  //collection = { count: 60, data: [] };
  constructor(public datepipe: DatePipe,private dialog: MatDialog,private router: Router,public apiService : ApiService,private route: ActivatedRoute,private datePipe: DatePipe) {
    //

  
   
// alert(router.url)
  /* this.goal_id=this.route.params['_value'].goal_id;
    console.log("this.route.params",this.route.params)
    let param = this.router.parseUrl(this.router.url);*/
    this.gift_id = this.router.getCurrentNavigation().extras.state.gift_id;
    this.goal_id = this.router.getCurrentNavigation().extras.state.goal_id;
    this.payment_id = this.router.getCurrentNavigation().extras.state.payment_id;
    console.log("this.router.getCurrentNavigation().extras.state.payment_id",this.router.getCurrentNavigation().extras.state.payment_id)
    if(this.goal_id){
      this.encID = btoa(this.goal_id);

    }
    else if(this.gift_id){
      this.encID = btoa(this.gift_id);
    }
 else{
   console.log(this.payment_id)
   this.encID = btoa(this.payment_id);
 }

   }
  
   pageChanged(event){
    this.config.currentPage = event;
  }
ngOnDestroy(){
  console.log("ngOnDestroy called")
}
  ngOnInit() { 
    console.log(this.gift_id);
    console.log(this.goal_id); 
    console.log(this.payment_id)
    if(this.gift_id != undefined){
      this.giftDiv = true;
      console.log("tayyab")
      this.createTable2();
      
    } 

    
    else if(this.goal_id != undefined){
      console.log("ngOnInit called")
      this.goalDiv = true;
      console.log(localStorage.getItem("user_data"));
      let value=JSON.parse(localStorage.getItem("user_data"));
    
      this.createTable();
    }
    else if(this.payment_id != undefined){
      this.paymentDiv = true;
      this.createTable3();
    }
   
  }

  cancel_gift(){
    if(this.giftStatus == 'Active'){
      this.showDialog = true;
      this.apiService.gift_all_data("https://dev.krowdbox.com/api/checkBankDetails",this.gift_id).subscribe((res)=>{
        console.log(res)
        if(res == 'bank exists'){
          if(confirm("Are you sure you want to cancel? You will be charged a 5% admin fee and the balance amount paid into your bank account ")) {

            this.apiService.gift_all_data("https://dev.krowdbox.com/api/cancelGift", this.gift_id).subscribe((res) =>{
              console.log(res);
              if(res == 'Cancelled'){
                 $('.margin_change').addClass('button_gray');
                $('.margin_change').removeClass('button_1');
                  this.cancelButton = false;
              }
          })
            
          }
          else{
            console.log("nothing")
          }
           
        }
        else{
          alert("Please add your bank account details before you can proceed")
          this.router.navigateByUrl('/verified-bank');
        }
      })

      
    }
    
  }
  giftUrl(){
  
    this.cashUrlDiv = true;
    this.cashGiftUrl = true;
    this.cashgiftMsg = false;
  }
  createTable3(){
setTimeout(()=>{
  this.apiService.receive_payment_all_data("https://dev.krowdbox.com/api/getReceivePaymentDetailDashboard",this.payment_id).subscribe((res)=>{
    console.log(res);
    if(res == 'No data found'){
      this.paymentEmpty = true;
    }
    else{
      console.log("receuve",res)
      let receive = res['0'];
      // this.amount_raised=receive['amount_raised'];
      this.receiveAmount_raised = receive.amount_paid;
      this.payment_amount=receive.payment_amount;
      this.balance_to_pay = receive.balance_to_pay;
      this.platform_fee = receive.platform_fee;
      this.receiveStatus=receive.status;
      if(this.payment_amount==" "){
        this.payment_amount=0;
      }

      if(this.receiveStatus=="Terminated" || this.receiveStatus=="completed"){

        this.activeReceive = true;
        
        $('.margin_change').addClass('button_gray');
        $('.margin_change').removeClass('button_1');
        $('margin_change1').addClass('button_gray');
        $('.margin_change1').removeClass('button_4');
      }
      this.payment_reference=receive.payment_reference;
      this.payment_date=receive.payment_date;
      console.log("goal data response getGoalDetailDashboard",res['0'])
    }
  })

  //customer list of contribution
  this.apiService.receive_payment_all_data("https://dev.krowdbox.com/api/getCustomerDetailDashboard",this.payment_id).subscribe((res)=>{
    this.customers=res;
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.customers.count
    };
    console.log("date",res)
    // this.variable=res;
    if(this.customers != 'Data not found'){

    console.log("response getContributorDetailDashboard",this.customers);
    
    for(let i=0;i<this.customers.length;i++){
      let temp=this.customers[i]; 
      this.customers[i].message=this.customers[i].message.replace(/,/g, " ");
      // console.log("this.contributors[i].message.replace",this.contributors[i].message.join(" "))
      console.log("this.contributors[i].message",this.customers[i].message)
      this.customers[i].created_at= this.datePipe.transform(new Date(temp['created_at']), 'dd-MM-yyyy');
    }
    console.log("this.contributors",this.customers)
  }
  else{
      return (this.paymentEmpty=true)
  }
  })
})
  }
  createTable2(){
    this.cashUrlDiv = false;
    setTimeout(()=> {
      this.apiService.gift_all_data("https://dev.krowdbox.com/api/getGiftDetailDashboard",this.gift_id).subscribe((res)=>{
      console.log(res);
      if(res == 'No data found'){
          this.giftEmpty = true;
      }
      else{
        this.giftName= res[0].recipient_name;
        this.giftNumber = res[0].recipient_number;
        this.giftAmount = res[0].amount;
        this.giftCreate_at = res[0].created_at;
        this.giftMsg = res[0].message;
        this.giftStatus = res[0].status;
        this.cashgiftMsg = true;
        if(this.giftStatus == 'Cancelled'){
          $('.margin_change').addClass('button_gray');
          $('.margin_change').removeClass('button_1');
        }
        else{
          this.cancelButton = true;
        }
        console.log(this.giftName);
      }
    
      });
    },200);
  }
//  ngOnDestroy(){
//   console.log("ngOnDestroy called");
//  }
  createTable() {
    
    setTimeout(() => {
    this.apiService.goal_all_data("https://dev.krowdbox.com/api/getGoalDetailDashboard",this.goal_id).subscribe((res)=>{
      let goal=res['0'];
      this.amount_raised=goal['amount_raised'];
      this.target_amount=goal['target_amount'];
      this.status=goal['status'];
   
      if(this.target_amount==" "){
        this.target_amount=0;
      }

      if(this.status=="Terminated" || this.status=="completed"){
        this.activeGoal = true;
        
        $('.margin_change').addClass('button_gray');
        $('.margin_change').removeClass('button_1');
      }
      this.goal_name=goal['goal_name'];
      this.target_date=goal['target_date'];
      console.log("goal data response getGoalDetailDashboard",res['0'])
    });


// goal 

    
    this.apiService.goal_all_data("https://dev.krowdbox.com/api/getContributorDetailDashboard",this.goal_id).subscribe((res)=>{
    this.contributors=res;
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.contributors.count
    };
    console.log("date",res)
    // this.variable=res;
    if(this.contributors != 'Data not found'){

    console.log("response getContributorDetailDashboard",this.contributors);
    
    for(let i=0;i<this.contributors.length;i++){
      let temp=this.contributors[i]; 
      this.contributors[i].message=this.contributors[i].message.replace(/,/g, " ");
      // console.log("this.contributors[i].message.replace",this.contributors[i].message.join(" "))
      console.log("this.contributors[i].message",this.contributors[i].message)
      this.contributors[i].created_at= this.datePipe.transform(new Date(temp['created_at']), 'dd-MM-yyyy');
    }
    console.log("this.contributors",this.contributors)
  }
  else{
      return (this.empty=true)
  }
    
    });
  },200)
    
  
    
   

    
    // this.dataSource.sort = this.sort;
  }
  function_view(message){

  if(message != null){

  this.display_msg=message;
  $(".alert1").removeClass('alertchange'); 
  $(".alert1").addClass('alert-success1');
  // $(".table").addClass('disable_table');
   
}
else{
  alert("No message")
}
  }
  viewdata(){
    console.log("viewdata")
  }
  asd(){

    this.share = true;
    this.msg=false;
    this.edit=false;
    this.terminate=false;
    this.fee=false;
  }
  asdeceive(){
    this.receiveShare = true;
    this.receiveMsg=false;
    this.editReceive=false;
    this.receiveTerminate=false;
    this.receiveFee=false;
  }
  fee_function(){
    console.log("fee")
    if(!this.fee){
    this.fee=true;
    
    this.msg=false;
    this.terminate=false;
    this.share = false;
    this.edit=false;
    this.apiService.goal_all_data("https://dev.krowdbox.com/api/viewYourFee",this.goal_id).subscribe((res)=>{
      let goals=res['0'];
      this.fee_amount_raised=goals['amount_raised']
      this.fee_balance_to_you=goals['balance_to_you'];
      this.fee_payment_gateway=goals['payment_gateway']
      this.fee_platform_fee=goals['platform_fee']
      console.log("response viewYourFee",res);

    });
  }
    else{
      this.fee=false;
      // this.msg=false;
      this.msg=true;
      this.terminate=false;
      this.edit=false;
    }
  }

  receiveFee_function(){
    console.log("fee")
    if(!this.receiveFee){
    this.receiveFee=true;
    
    this.receiveMsg=false;
    this.receiveTerminate=false;
    this.receiveShare = false;
    this.editReceive=false;
    }
    else{
      this.receiveFee=false;
      // this.msg=false;
      this.receiveMsg=true;
      this.receiveTerminate=false;
      this.editReceive=false;
    }
  }

  terminate_receivePayment(){
    if(confirm("Are you sure you want to process early access? On accepting, please allow system to process and wait for confirmation.")){
      this.receiveData['payment_id'] = this.payment_id;
      this.receiveData['balance'] = this.balance_to_pay;
      this.apiService.terminat_payment("https://dev.krowdbox.com/api/terminate_receivePayment",this.receiveData).subscribe((res)=>{
        let receives =res
        if(res == "Payment terminated successfully"){
          alert("Early access processed successfully");
          this.router.navigate(['/maindashboard']);
        }
        else{
          console.log("something went wrong")
        }
      })
    }
  }
terminate_goal(){
  if(confirm("Are you sure you want to terminate?")) {
    this.goalData['goal_id'] = this.goal_id;
    
    this.goalData['balance'] = this.fee_balance_to_you;
    console.log("goalData",this.goalData);
   this.apiService.terminat_goal("https://dev.krowdbox.com/api/terminateGoal",this.goalData).subscribe((res)=>{
      let goals=res
     console.log("response terminateGoal",res);
     if(res == "Goal terminated successfully"){

      alert(goals)
      this.router.navigate(['/maindashboard']); 
     }
     else{
      console.log("something went wrong")
     }
    //  $('.margin_change').addClass('button_5');
    //  $('.margin_change').removeClass('button_1');
    
    });
  }
}

  Terminate_function(){
    if(!this.terminate){
      this.terminate=true;
      this.msg=false;
      this.fee=false;
      this.edit=false;
      this.share = false;
      this.apiService.goal_all_data("https://dev.krowdbox.com/api/viewYourFee",this.goal_id).subscribe((res)=>{
        let goals=res['0'];
        this.fee_amount_raised=goals['amount_raised']
        this.fee_balance_to_you=goals['balance_to_you']
        // this.terminated_balance = goals['balance_to_you']  * 0.95;
        // this.terminated_balance1 = this.terminated_balance.toFixed(2)
        this.fee_payment_gateway=goals['payment_gateway']
        this.fee_platform_fee=goals['platform_fee']
        console.log("response viewYourFee",res);
  
      });
    }
      else{
        this.terminate=false;
        this.msg=true;
        this.fee=false;
        this.edit=false;
      }
  }

  receiveTerminate_function(){
    if(!this.receiveTerminate){
      alert("You can have early access to your balance should your customer complete payment before the due date. Please note this will cancel your payment instruction")
      this.receiveTerminate=true;
      this.receiveMsg=false;
      this.receiveFee=false;
      this.editReceive=false;
      this.receiveShare = false;
    }
    else{
      this.receiveTerminate=false;
      this.receiveMsg=true;
      this.receiveFee=false;
      this.editReceive=false;
    }
  }

  editReceive_function(){
    if(!this.editReceive){
      this.editReceive=true;
      this.receiveTerminate=false;
      this.receiveMsg=false;
      this.receiveFee=false;
      this.receiveShare=false;
    }
    else{
      this.editReceive=false;
      this.receiveTerminate=false;
      this.receiveMsg=true;
      this.receiveFee=false;
      this.receiveShare = false;
    }

  }
  edit_function(){
    if(!this.edit){
      this.edit=true;
      this.terminate=false;
      this.msg=false;
      this.fee=false;
      this.share = false;
      console.log(this.target_date);
    
    }
      else{
        this.edit=false;
        this.terminate=false;
        this.msg=true;
        this.fee=false;
        this.share = false;
      }
  }

  edit_Receive(){
    console.log("target date",this.payment_date);
    console.log("this edit receive",this.receiveUser);
    console.log("receive date",this._dateReceive);

    this.user['newdate']=this._dateReceive['year']+'/'+this._dateReceive['month']+'/'+this._dateReceive['day']
    console.log("select by you date",this.user['newdate'],new Date(this.user['newdate']).valueOf())
    if(new Date(this.user['newdate']).valueOf() > new Date(this.payment_date).valueOf()){
      this.dateReceive=true;

      this.user['payment_id'] = this.payment_id;
      this.user['payment_amount'] = this.receiveUser['amount'];
      this.user['payment_date'] = this.user['newdate'];

      this.apiService.register("https://dev.krowdbox.com/api/editReceive",this.user).subscribe((res)=>{
      alert(res)
      this.createTable3();
      this.receiveUser['amount']=" ";
      this._dateReceive=" ";
      });
    }
    else{
      this.dateReceive=false;
    }
  }
  edit_goal(){
  console.log("this.target_date",this.target_date)
  console.log("user",this.user)
  this.user['newdate']=this._date['year']+'/'+this._date['month']+'/'+this._date['day']
   console.log("select by you date",this.user['newdate'],new Date(this.user['newdate']).valueOf())
   console.log("current date",this.datepipe.transform(new Date(), 'MM/dd/YYYY'),new Date().valueOf())
    console.log("old target date",this.target_date,new Date(this.target_date).valueOf())
    console.log("check if new date is greater than old target date",new Date(this.user['newdate']).valueOf() > new Date(this.target_date).valueOf())
   console.log("this.user['target_amount'] > this.target_date",this.user['amount'] > this.target_amount)

    if(new Date(this.user['newdate']).valueOf() > new Date(this.target_date).valueOf()){
     this.date=true;
  
    //  editGoal

    this.user['goal_id']=this.goal_id;
    this.user['target_amount']=this.user['amount'];

    this.user['target_date']=this.user['newdate'];
    console.log("user",this.user);
    this.apiService.register("https://dev.krowdbox.com/api/editGoal",this.user).subscribe((res)=>{
      let goals=res;     
      console.log("response editGoal",res);
    
      
      alert(res);
      this.createTable();
      this.user['amount']=" ";
      this._date=" ";
    });
    }
    else{
      // alert("something went wrong try again")
      this.date=false;
    }
  }
  successMessage1(){
    $(".alert1").removeClass('alert-success1');
    $(".alert1").addClass('alertchange');
  }
  copyText(id){
    // getData1
    // console.log("id",id);
    // console.log("id",atob(id));
    // console.log("bto",btoa(id));
    // var encodedString=btoa(id);
    // console.log("bto",atob(encodedString));
    
    
  //  let val ="https://dev.krowdbox.com/copy-link?id="+btoa(id);
   let val ="https://dev.krowdbox.com/kx.php?id="+id;

    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
  
    copyText1(id){
      let val ="https://dev.krowdbox.com/share/payment.php?id="+id;

      let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}

