import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateGoalComponent } from '../create-goal/create-goal.component';
import { BankComponent } from '../bank/bank.component';
import { VerifiedBankComponent } from '../verified-bank/verified-bank.component';
import { ShareGoalComponent } from '../share-goal/share-goal.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SettingsComponent } from '../settings/settings.component';
import { Dashboard1Component } from '../dashboard1/dashboard1.component';
import { SendgiftComponent } from '../sendgift/sendgift.component';
import { ReceivePaymentComponent } from '../receive-payment/receive-payment.component';
const routes: Routes = [

 { path: 'create-goal',  component: CreateGoalComponent },
 { path: 'bank',  component: BankComponent},
 { path: 'verified-bank',  component: VerifiedBankComponent },
 { path: 'share-goal',  component: ShareGoalComponent },
//  { path: 'dashboard/:goal_id',  component: DashboardComponent },
 { path: 'dashboard',  component: DashboardComponent },
 { path: 'settings',  component: SettingsComponent },
 { path: 'maindashboard',  component: Dashboard1Component },
{ path: 'sendgift', component: SendgiftComponent},
{ path: 'receive-payment', component: ReceivePaymentComponent},

{ path: '',   redirectTo: '/create-goal', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateTargetRoutingModule { }
