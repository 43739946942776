import { Component, OnInit, Inject ,ViewEncapsulation, OnDestroy} from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-msg',
  templateUrl: './msg.component.html',
  styleUrls: ['./msg.component.css'],
  // encapsulation: ViewEncapsulation.None
 
})
export class MsgComponent implements OnInit ,OnDestroy {
 
  ngOnInit() {
  }
  
 ngOnDestroy(){
   console.log("OnDestroy called")
 }
  message:any;
  cancelButtonText = "Cancel"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<MsgComponent>) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
    // this.dialogRef.updateSize('300vw','300vw')
  }

  onConfirmClick(): void {
    this.dialogRef.close();
  }

}
