import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient) { }

	public register(url,data){
    	return this.httpClient.post(url,data);

	}
	public get_all(url,r_id){
    	return this.httpClient.post(url,{r_id:r_id});

	}
	public goal_all_data(url,goal_id){
		return this.httpClient.post(url,{goal_id:goal_id});

	}
	public receive_payment_all_data(url,payment_id){
		return this.httpClient.post(url,{payment_id:payment_id});
	}
	public terminat_goal(url,goalData){
		return this.httpClient.post(url,goalData);

	}
	public terminat_payment(url,receiveData){
		return this.httpClient.post(url,receiveData);
	}
	public gift_all_data(url,gift_id){
		return this.httpClient.post(url,{gift_id:gift_id});
	}

	public getData(data){
		return this.httpClient.get(data);

	}
	public getData_2(url,data){

		return this.httpClient.post(url,data);
	}

	public verifyData(url,data){

		return this.httpClient.post(url,{id:data});
	}
	public verify1Data(url,data)
	{
		return this.httpClient.post(url,{request_id:data});
	}
	public getData1(data){
		return this.httpClient.get(data);

	}
	public post_data(url,data){
		return this.httpClient.post(url,data);
	}
	public post_data1(url,data1){
		return this.httpClient.post(url,{africanID:data1});
	}
}
