import { Component, OnInit, OnDestroy } from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ReviewGoalComponent } from '../review-goal/review-goal.component';
import { ReviewDialogComponent } from '../review-dialog/review-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import { FormGroup , FormControl ,Validators, NgForm,FormsModule} from '@angular/forms';
import { ApiService } from '../api.service';
import { Router,ActivatedRoute ,NavigationExtras }              from '@angular/router'; 

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-verified-bank',
  templateUrl: './verified-bank.component.html',
  styleUrls: ['./verified-bank.component.css']
})
export class VerifiedBankComponent implements OnInit ,OnDestroy {
user:any={};

data:any;
 user1:any={};

constructor(private router: Router,public dialog: MatDialog,public apiService:ApiService) { 

console.log(JSON.parse(localStorage.getItem("user_data")))
 this.check_function();
}
  
check_function(){
  if(JSON.parse(localStorage.getItem("user_data"))){
    let value=JSON.parse(localStorage.getItem("user_data"));
    console.log("valuenew",value)
   // console.log("data_storage",JSON.parse(localStorage.getItem("user_data")))
    let value2=value['0'];
    console.log("value2",value2)
    console.log("data",value2.id)
    
    this.user.r_id=value2.id;
    console.log("user",this.user)
    
            console.log("r_id",this.user)
               this.apiService.register("https://dev.krowdbox.com/api/getBankDetails",this.user).subscribe((res)=>{
                        console.log("res",res)
                  if(res != "No data found"){
                    this.data=res;
                  }else{
                    this.data=[];
                  }
            });
            
            
    }
    else{
      console.log("empty")
    }
}
  ngOnInit() {

 


  setTimeout(function(){ 

      //alert("Hello"); 
      $(".mat-expansion-panel-body").css("padding","0 1rem 0 0");
      // this.router.navigate(['/verified-bank']);
    }, 100);


  }

  save(){
    let value="verified-bank/bank";  
    this.router.navigate(['/bank'],{ queryParams:  {"value":value} , skipLocationChange: true}); 
}

ngOnDestroy(){
  console.log("ngOnDestroy called");
 }


delete_account(id){
  console.log("delete_account",id);
  if(confirm("Are you sure you want to delete?")) {
    this.user1.bank_name=id;
    this.user1.r_id=this.user;
    console.log("r_id",this.user)
    console.log("this.user",this.user1)
     this.apiService.register("https://dev.krowdbox.com/api/deleteBankDetails",this.user1).subscribe((res)=>{
                console.log("res",res)
          this.data=res;
          console.log("data",this.data);
          
          this.check_function();
    });
  }
  
}
}