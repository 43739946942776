import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router,ActivatedRoute ,NavigationExtras,NavigationEnd }from '@angular/router'; 
import { ApiService } from '../api.service';
// import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-dashboard1',
  templateUrl: './dashboard1.component.html',
  styleUrls: ['./dashboard1.component.css']
})
export class Dashboard1Component implements OnInit{
  goals:any;
  receivePayments:any;  
  activated: boolean=false;
  Inactive: boolean=false;
  Terminated: boolean=false;
  completed: boolean=false;
  Pending:boolean=false;
  recent: any;
  con: boolean=false;
  gifts: any;
  giftNoData: boolean=true;paynow: boolean;
;
  goalNoData: boolean=true;
  receivePaymentNoData: boolean=true;
  constructor(private router: Router,public apiService : ApiService,) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
    console.log(localStorage.getItem("user_data"));
    let value=JSON.parse(localStorage.getItem("user_data"));
    console.log(value[0].id);
    this.display()
  }


  View(goal_id){
    console.log("goal_id",goal_id);
 

 
    // this.router.navigate(['/dashboard',goal_id]); 
    // this.router.navigate(['/dashboard'],{ queryParams:  {"value":goal_id} , skipLocationChange: true}); 
    this.router.navigate(['/dashboard'], { state: { goal_id: goal_id } }); 
  }

  View1(gift_id){
    console.log("gift_id",gift_id)
    this.router.navigate(['/dashboard'],{state: { gift_id: gift_id} });
  }

  View2(payment_id){   
    console.log("payment_id",payment_id)  
   this.router.navigate(['/dashboard'],{state: { payment_id : payment_id} });
  }
  creategoal(){
    this.router.navigate(['/create-goal']);
  }
  display(){
    

   
    let value=JSON.parse(localStorage.getItem("user_data"));
    console.log(value[0].id);
    let r_id=value[0].id;

    this.apiService.get_all("https://dev.krowdbox.com/api/getMainDashboardGiftDetail",r_id).subscribe((res)=>{
      console.log(res)
      if(res == "No data found"){
          this.giftNoData = false;
      }
      else{
        this.gifts = res;
        console.log(this.gifts);
        console.log("status",this.gifts.length);
        // for(let i=0;i<this.gifts.length; i++){
        //   console.log("loop status", this.gifts[i].status)
        //   if(this.gifts[i].status == 'Pending'){
        //     this.paynow = true;
        //     console.log(this.paynow)
        //   }
        //   else{
        //     this.paynow = false;
        //   }
        // }
        console.log("sendgifts",res);
      }
     
    });

     // below is for goal data
    this.apiService.get_all("https://dev.krowdbox.com/api/getMainDashboardGoalDetail",r_id).subscribe((res)=>{
      console.log(res)
      if(res == "No data found"){
      
          this.goalNoData = false;
      }
      else{
        this.goals=res
      console.log("response",this.goals);
      for(let i=0;i<=this.goals.length;i++){
        let remain= this.goals[i];
        // console.log("tayyab checking",remain);
        if(remain.daysRemaining==0 || remain.daysRemaining < 0 ){
          remain.daysRemaining = 0;
          // this.goals[i].status = 'Completed';
          
        }
        if(remain.status=='Activate'){
          this.activated=true;
        }
        if(remain.status=='Inactive'){
          this.Inactive=true;
        }
        if(remain.status=='Terminated'){
          this.Terminated=true;
        }
        if(remain.status=='Completed'){
          this.completed=true;
        }
        if(remain.status=='Pending'){
          this.Pending=true;
        }
        // save as draft
      }

      }

      
    });

   //below is for receive paymentts
   this.apiService.get_all("https://dev.krowdbox.com/api/getMainDashboardReceivePaymentDetail",r_id).subscribe((res)=>{
     if(res == 'No data found'){
      this.receivePaymentNoData = false;
     }
     else{
       console.log("receive payment",res)
      this.receivePayments = res;
      for(let i=0;i<=this.receivePayments.length;i++){
        let remain= this.receivePayments[i];
        if(remain.daysRemaining==0 || remain.daysRemaining < 0 ){
          remain.daysRemaining = 0;
          // this.goals[i].status = 'Completed';
          
        }
        if(remain.status=='Active'){
          this.activated=true;
        }
        if(remain.status=='Inactive'){
          this.Inactive=true;
        }
        if(remain.status=='Terminated'){
          this.Terminated=true;
        }
        if(remain.status=='Completed'){
          this.completed=true;
        }
        if(remain.status=='Pending'){
          this.Pending=true;
        }

      }
     }
   })


    console.log("delete",r_id);
    this.apiService.get_all("https://dev.krowdbox.com/api/recentActivity",r_id).subscribe((res)=>{
        console.log(res)
        if(res == 'Something went wrong'){          
          this.con=true;
        }else{
          this.recent=res;
          this.con=false;
        }
  });


  }
  delete(goal_id){
   
    this.apiService.goal_all_data("https://dev.krowdbox.com/api/deleteGoal",goal_id).subscribe((res)=>{
        alert(res)
        this.display();
  });
this.display();
  }
  Activated(goal_id){
  
    this.apiService.goal_all_data("https://dev.krowdbox.com/api/activatingGoal",goal_id).subscribe((res)=>{
      alert("Goal activated successfully");
      this.display();
      this.router.navigate(['/share-goal']);
});

  }
  Activated_payment(payment_id){
      this.apiService.receive_payment_all_data("https://dev.krowdbox.com/api/activatingPayment",payment_id).subscribe((res)=>{
        let dataObj = res;
      if(dataObj['message'] = "Payment activated successfully"){
         
          let pay_id = btoa(dataObj['data']); 
          let cellphone = dataObj['cellphone'];
          let name = dataObj['name'];
          let firstname = dataObj['firstname'];
          let reference = dataObj['reference'];
          this.apiService.getData("https://platform.clickatell.com/messages/http/send?apiKey=LQQrj8_0RY6y9XljTlY_TQ==&to=+"+'+27'+cellphone+"+&content=Hello "+name+", " +firstname+ " has sent you a payment link for "+reference+ ". Pay for it by clicking here: https://dev.krowdbox.com/share/payment.php?id="+pay_id).subscribe((res)=>{
            console.log("message sent");
            alert("Payment activated successfully")
            this.display();
          })
          // alert("Payment activated successfully");
          // this.display();
        }
        else{
          alert("Something went wrong");
        }
     

      })
  }

}
