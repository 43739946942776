import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, FormArray, FormControl ,Validators, NgForm,FormsModule} from '@angular/forms';
import { ApiService } from '../api.service';
import { Router,ActivatedRoute ,NavigationExtras,RouterEvent, NavigationEnd  }              from '@angular/router'; 
import { CreateTargetComponent }  from '../create-target/create-target.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common'
import * as moment from 'moment';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-receive-payment',
  templateUrl: './receive-payment.component.html',
  styleUrls: ['./receive-payment.component.css']
})
export class ReceivePaymentComponent implements OnChanges,OnInit,OnDestroy {
  panelOpenState = false;
  data:any;
  rinki:any;
  
  user:any={};
  notification_value:any;
  data1:any;
  waiting:boolean=false;
  // goal:any;
  showDialog:boolean=false;
  add_status:boolean=false;
  review_status:boolean=false;
  own_contribution_percent2:boolean=false;
  user_id:any;
  data_res:any={};
  own_contribution:any;
  choose_bank:boolean=false;
  user_details:any={};
  user_details1:any={};
  bank_id:any={};
  bank_id1:any={};
  own_contribution_percent1:boolean=false;
  select_bank:any;
  select_bank2:any;
  select_bank3:any;
  select_bank4:any;
  select_bank5:any;
  select_bank6:any;
  isChecked1:boolean=false;
  isChecked2:boolean=false;
  isChecked3:boolean=false;
  isChecked4:boolean=false;
  isChecked5:boolean=false;
  no_bank_account:boolean=true;
  isChecked6:boolean=false;
  value_select:any;
  current_date:any;
  validate_date:boolean=false;
  validate_amount:boolean=false;
  validate_duration:boolean=false;
  variable_condition:boolean;
  next_date:any;
  currentUrl:any;
  previousUrl:any;
  categories:any={};
  name:any;
  value:any;
  // data: any={};
  amount: number;
  user_data: any;
  r_id: any;
  formData: any;
  customer_name: any;
  customer_email: any;
  customer_cellphone: any;
  message: any;
  addedAmount: number;
  msgArray: any;
  cityName: any;
  category: any;
  categorydata: any;
  cat: boolean;
  recipient_name_array: any;
  title: string;
  payment_date_obj: any={};

  payment_date_obj2: any;
  // data1: any={};
  
  myForm: FormGroup;
   form = new FormGroup({
           payment_reference: new FormControl('', Validators.required),
           payment_date: new FormControl('', Validators.required),
           payment_amount: new FormControl('', Validators.required),
           customer_name: new FormControl('', Validators.required),
           customer_email: new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
         amount: new FormControl('', Validators.required),
         customer_cellphone: new FormControl('', [Validators.required,Validators.required,Validators.pattern("[0-9]{10}")]),
         message: new FormControl('', Validators.required)
           // own_contribution_percent: new FormControl('', Validators.required),
    });
  localData: string;
  lastname: any;
    
    
  constructor(private fb: FormBuilder,public datepipe: DatePipe,private router: Router,public dialog: MatDialog,public apiService:ApiService) { 
    if(JSON.parse(localStorage.getItem("user_data"))){
      let value=JSON.parse(localStorage.getItem("user_data"));
      console.log("valuenew",value)
      let value2=value['0'];
      console.log("value2",value2)
      console.log("data",value2.id)
      this.user_id=value2['id'];
      this.lastname = value2['firstname'];
      console.log("user_id",this.user_id)
      this.check_account();
    }
    else{
      console.log("empty")
    }
  }

  ngOnInit() {

    console.log("surname",this.lastname);
    console.log("ngOnInit called")
    this.myForm = this.fb.group({
      useremail: this.fb.array([])
    });
    console.log("this.user",this.user)
  }
  ngOnChanges(changes: SimpleChanges) {
   
  }
  ngOnDestroy(){
    console.log("ngOnDestroy called")
  }
  input1(){
    this.validate_amount=false;

  }

  review(){
    if(this.category != undefined){
    console.log("tayyab")
    console.log("data",this.user)
    this.formData = this.form.value;
    console.log('formdata',this.formData)
    console.log("target_date",this.formData.payment_date)
    this.payment_date_obj= this.formData.payment_date;
    console.log('date obj',this.payment_date_obj.year)
    // console.log("target_date",this.target_date['month'])
    // console.log("target_date",this.target_date['day'])
     this.payment_date_obj2=this.payment_date_obj.year+'/'+this.payment_date_obj.month+'/'+this.payment_date_obj.day;
     this.user_details.payment_date = this.payment_date_obj2;
     console.log('payment obj',this.payment_date_obj2)
      
    console.log("category", this.category)
    
    this.user_details.payment_reference = this.formData.payment_reference;
    this.user_details.payment_amount = this.formData.payment_amount;
    this.user_details.customer_name = this.formData.customer_name;
    this.user_details.customer_email = this.formData.customer_email;
    this.user_details.customer_cellphone = this.formData.customer_cellphone;
    this.user_details.bank_name = this.value_select;
    this.user_details.r_id = this.user_id;
    this.user_details.category = this.category;
     
     if(this.user.payment_amount>30000){
      this.validate_amount=true;
      
      
    }
    else{
      this.showDialog = true;
    }
    }
    else{
      alert("Please select category")
    }
    // this.user_details. = this.formData.payment_reference;
  }
  check_account(){

    this.user.r_id=this.user_id
    console.log("this user",this.user)
    this.apiService.register("https://dev.krowdbox.com/api/getBankDetails",this.user).subscribe((res)=>{
      console.log("check_account",res)
      this.data=res;
      console.log("data",this.data);
  
      if(this.data == "No data found"){
        console.log("data No data found");
        this.no_bank_account=true;
      }
      else{
        console.log("data");
        this.no_bank_account=false;
      }
          
    });
  
  }
  selectChangeHandler1(event: any){
    this.category = event.target.value; 
    console.log(event.target.value);
  }
  
  get_value(value){

 
    console.log(" Value is : ", value.target.value );
    this.value_select=value.target.value;
    localStorage.setItem("value_select",this.value_select);
   }
   get_value1(value){
     console.log("get vlaue 2",value.target.value)
   }
   activate(){
    this.waiting = true;
     this.user_details.status = 'Active'; 
     console.log("this value select",this.value_select);

     console.log("r_id", this.user_id)
    this.user_details1.bank_name = this.value_select;
    this.user_details1.r_id = this.user_id;

     this.apiService.register("https://dev.krowdbox.com/api/getbankid",this.user_details1).subscribe((res)=>{
         
      this.bank_id = res;
      this.user_details.bank_id = this.bank_id;
    this.apiService.register("https://dev.krowdbox.com/api/createPayment",this.user_details).subscribe((res)=>{
      console.log(res)
      let dataObj = res;
      
      console.log("dataObj",dataObj['message'])
      if(dataObj['message'] == 'Data saved successfully'){
        
       let pay_id = btoa(dataObj['data']); 
       let pay_ref = dataObj['payment_reference'];
       console.log("pay_ref",pay_ref);
        this.apiService.getData("https://platform.clickatell.com/messages/http/send?apiKey=LQQrj8_0RY6y9XljTlY_TQ==&to=+"+'+27'+this.user_details.customer_cellphone+"+&content=Hello "+this.user_details.customer_name+", "+this.lastname+ " has sent you a payment link for "+pay_ref+". Pay for it by clicking here: https://dev.krowdbox.com/share/payment.php?id="+pay_id).subscribe((res)=>{
          console.log("message sent");
          this.waiting = false;
          alert("Payment created successfully")
        this.user_details = {};
        this.router.navigate(['/maindashboard']);
        })

      }
      else{

      }
    })


   })

   }

   addaccount(){
   // console.log("come in",value);
    console.log("user_data",this.user);
    localStorage.setItem("user_target",JSON.stringify(this.user));

    this.add_status=true;
    console.log("add")
    let value="verified-bank/bank";
    this.router.navigate(['/bank'],{ queryParams:  {"value":value} , skipLocationChange: false}); 
  }

   save(){
     this.user_details.status = 'Pending';
     this.apiService.register("https://dev.krowdbox.com/api/savePayment",this.user_details).subscribe((res)=>{
      console.log(res)
      if(res == 'Data saved successfully'){
        alert("Payment saved successfully");
        this.user_details = {};
        this.router.navigate(['/maindashboard']);
      }
      else{

      }
    })
   }

  Delete(){
    this.user_details = {};
    this.user ={};
    this.value_select = '';
    this.customer_name = '';
    this.customer_email = '';
    this.customer_cellphone = '';
    this.category = '';
    $("input:radio").attr("checked", false);
    this.showDialog = false;  
    $("#unselect").val("none");
   // $('.chb').prop('checked', false);

  }
}
