import { Component, OnInit, OnDestroy } from '@angular/core';

import { ApiService } from '../api.service';
import { Router,ActivatedRoute ,NavigationExtras }from '@angular/router'; 


@Component({
  selector: 'app-share-goal',
  templateUrl: './share-goal.component.html',
  styleUrls: ['./share-goal.component.css']
})
export class ShareGoalComponent implements OnInit ,OnDestroy{
  data:any={};
  value:any=[];
  empty:boolean=true;
  constructor(public apiService : ApiService,private router: Router) { }

  ngOnInit() {
    
      this.api_call();



     
     
  }

  ngOnDestroy(){
    console.log("ngOnDestroy called");
   }
  api_call(){
    console.log("local",JSON.parse(localStorage.getItem("user_data")));
    let value=JSON.parse(localStorage.getItem("user_data"));
    this.data['r_id']=value['0'].id;
    console.log("hahah",this.data['r_id']);
    this.apiService.register("https://dev.krowdbox.com/api/shareGoal",this.data).subscribe((res)=>{
      console.log("tayyab chekin",res['data']);
      if(res['data']!='Something went wrong'){
        this.value=res['data'];        
        console.log("value_data",this.value['id'])
        console.log("res",res['data'])
        console.log("res",this.value.length);
        for(var i=0;i<this.value.length;i++)
        { let temp=this.value[i].id;
          
          this.value[i].id=btoa(temp);
          console.log("encrypted",this.value[i].id)
          // localStorage.setItem('')
          console.log("decrypted",atob(this.value[i].id))
        }
        console.log('value',this.value)
        if(this.value.length==0){
          console.log("empty");
          this.empty=false;
        }
        else{
          console.log("data");
          
          this.empty=true;
        }
      }

    })
  }
  addgoal(){
    this.router.navigate(['/create-goal']); 
  }

  copyText(id){
    // getData1
    console.log("id",id);
    console.log("id",atob(id));
    // console.log("bto",btoa(id));
    // var encodedString=btoa(id);
    // console.log("bto",atob(encodedString));
    
    
  //  let val ="https://dev.krowdbox.com/copy-link?id="+btoa(id);
   let val ="https://dev.krowdbox.com/kx.php?id="+id;

    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
}
