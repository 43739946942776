import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PersonalComponent }    from '../personal/personal.component';
import { WorkComponent }        from '../work/work.component';
import { AddressComponent }     from '../address/address.component';
import { ResultComponent }      from '../result/result.component';
import { DetailsComponent } from '../details/details.component';

import { WorkflowGuard }        from '../workflow/workflow-guard.service';
import { WorkflowService }      from '../workflow/workflow.service';
// import { NavbarComponent }    from '../navbar/navbar.component';


const routes: Routes = [

 // 1st Route
    { path: 'personal',  component: PersonalComponent },
    { path: 'details',  component: DetailsComponent, canActivate: [WorkflowGuard]  },
    // 2nd Route
    { path: 'work',  component: WorkComponent, canActivate: [WorkflowGuard] },
    // 3rd Route
    { path: 'address',  component: AddressComponent, canActivate: [WorkflowGuard] },
    // 4th Route
    { path: 'result',  component: ResultComponent, canActivate: [WorkflowGuard] },
    // 5th Route
    { path: '',   redirectTo: '/personal', pathMatch: 'full' },
    // 6th Route
    { path: '**', component: PersonalComponent }



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ WorkflowGuard ]
})
export class AdminRoutingModule { }
