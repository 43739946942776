import { NgModule }             from '@angular/core';

import { AdminComponent } from './admin/admin.component';

import { Routes, RouterModule } from '@angular/router';
import { CreateTargetComponent }  from './create-target/create-target.component';

import { CreateTargetModule } from './create-target/create-target.module';
import { LoginComponent } from './login/login.component';
import { CopyLinkComponent } from './copy-link/copy-link.component';
import { ForgetComponent } from './forget/forget.component';


export const appRoutes: Routes = [
  { path: 'forget',  component: ForgetComponent },
      { path: '',  component: LoginComponent},

      { path: '',  component: CreateTargetComponent,
        children: [

                {
                  path: '',
                  loadChildren:() => import('./create-target/create-target.module').then(m => m.CreateTargetModule)
                }
                ]
            },
         {
            path: '',
            component: AdminComponent,
            children: [
                {
                  path: '',
                  loadChildren:() => import('./admin/admin.module').then(m => m.AdminModule)
                }
            ]
        },
        { path: 'copy-link',  component: CopyLinkComponent },
      
      // create-target 

        
           
];

@NgModule({
  imports: [
  RouterModule.forRoot(appRoutes, { useHash:true} )
   // RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule],
  providers: [
  // WorkflowGuard
  ],
   declarations: [ AdminComponent,  ],
})

export class AppRoutingModule {}