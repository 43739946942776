import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute ,NavigationExtras ,Routes, RouterModule,} from '@angular/router'; 
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-create-target',
  templateUrl: './create-target.component.html',
  styleUrls: ['./create-target.component.css']
})
export class CreateTargetComponent implements OnInit {

  constructor(private router: Router,) {
    if(this.router.url =="/dashboard"){
      this.router.navigate(["/maindashboard"]);

    }

    if(sessionStorage.getItem("user_logged")=="true"){
      // alert("true");
      setInterval(function(){ 

        if(router.url=='/bank?value=change'){
          $("#top").addClass('disbled');
          // $("#loader").addClass('loader');
          
        }else if(router.url== "/bank?value=unchange"){
          $("#top").removeClass('disbled');
          // $("#loader").removeClass('loader');
         
      
          
        }else{
          $("#top").removeClass('disbled');
        }
        // else if(router.url=='/create-goal'){
        //   $(".btn").removeClass('is_active');
        //   $("#creagoal").addClass('is_active');
        // }
      
      }, 50);
  
    }
    else{
      // alert("false");
      this.router.navigate(['/']);
    }
   
   }

  ngOnInit() {
    console.log("user_logged",  sessionStorage.getItem("user_logged"))
    console.log("router.url",this.router.url);
  }

}
