import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kpis',
  templateUrl: './kpis.component.html',
  styleUrls: ['./kpis.component.css']
})
export class KpisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
