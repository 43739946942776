
import {   Component, OnInit, Input, Output, OnChanges, EventEmitter, OnDestroy, 
  } from '@angular/core';

  import {
    trigger, state, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-review-dialog',
  templateUrl: './review-dialog.component.html',
  styleUrls: ['./review-dialog.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class ReviewDialogComponent implements OnInit,OnDestroy {
@Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }
  ngOnDestroy(){
    console.log("ngOnDestroy called");
   }
  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

}
