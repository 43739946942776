import { Component, OnInit,ViewEncapsulation, OnDestroy } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import { ApiService } from '../api.service';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
// import { FormControl } from '@angular/forms';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  providers: [NgbTabsetConfig]
})
export class SettingsComponent implements OnInit,OnDestroy {
  fname:any;
  lname:any;
  email:any;
  address:any;
  cellphone:any;
  africanID:any;
  // cellphone:any;
  user:any={};
  r_id:any;
  check: boolean=false;
  heroForm: FormGroup;
  
  constructor(public apiService:ApiService,config: NgbTabsetConfig) { 
    config.justify = 'fill';
    config.type = 'pills';
    
    
  }

  ngOnInit() {
    // nav-link active
    
    this.heroForm = new FormGroup({
      'password': new FormControl(this.user.npassword,[
        Validators.required,
        Validators.pattern('(?=.*\d)(?=.*[a-z]).{6,}'),
       
      ]),
      'cpassword': new FormControl(this.user.password,[
        Validators.required,
        Validators.pattern('(?=.*\d)(?=.*[a-z]).{6,}'),
       
      ]),    
    });

    console.log(localStorage.getItem("user_data"));
    let value=JSON.parse(localStorage.getItem("user_data"));
    console.log("value",value);
    this.fname=value[0].firstname;
    console.log("fname",this.fname)
    this.lname=value[0].surname;
    this.email=value[0].email;
    this.cellphone=value[0].cellphone;
    this.africanID=value[0].africanID;
    this.address=value[0].address;
    this.r_id=value[0].id;
console.log("r_id",this.r_id)


    
  }

  funtion1(){
    this.check=false;
  }
  ngOnDestroy(){
    console.log("ngOnDestroy called");
   }
  change(){
    this.r_id;
    console.log("user",this.user);
    this.user['r_id']=this.r_id;
    if( this.user['password']== this.user['npassword']){
    this.apiService.register("https://dev.krowdbox.com/api/resetPassword",this.user).subscribe((res)=>{
          console.log("goal data response resetPassword",res);
          alert("Password changed");          
          this.user['password']= '';
          this.user['npassword']='';
    });
  }
  else{
    this.check=true;
  }
   
  }

}
